* {
  margin: 0px;
  padding: 0px;
  font-family: Roboto, sans-serif;  
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Italic.ttf');
    font-weight: normal;
    font-style: italic;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-BoldItalic.ttf');
    font-weight: bold;
    font-style: italic;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Thin.ttf');
    font-weight: 200;
    font-style: normal;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-ThinItalic.ttf');
    font-weight: 200;
    font-style: italic;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Light.ttf');
    font-weight: 100;
    font-style: normal;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-LightItalic.ttf');
    font-weight: 100;
    font-style: italic;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Medium.ttf');
    font-weight: 300;
    font-style: normal;
}
 
@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-MediumItalic.ttf');
    font-weight: 300;
    font-style: italic;
}